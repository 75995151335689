/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #0E2D45;
  --ion-color-primary-rgb: 233,8,140;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #0a2235;
  --ion-color-primary-tint: #12344e;
  /** secondary **/
  --ion-color-secondary: #000000;
  --ion-color-secondary-rgb: 0,0,0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #000000;
  --ion-color-secondary-tint: #1a1a1a;
  /** tertiary **/
  --ion-color-tertiary: #A7C412;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #93ad0f;
  --ion-color-tertiary-tint: #b4d414;
  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Colores para botones cambio etapas oportunidad **/
  /** perdido **/
  --ion-color-perdido: #ea514f;
  --ion-color-perdido-rgb: 234,81,79;
  --ion-color-perdido-contrast: #ffffff;
  --ion-color-perdido-contrast-rgb: 255,255,255;
  --ion-color-perdido-shade: #ce4746;
  --ion-color-perdido-tint: #ec6261;
  /** avanzar **/
  --ion-color-avanzar: #3b9ad9;
  --ion-color-avanzar-rgb: 59,154,217;
  --ion-color-avanzar-contrast: #ffffff;
  --ion-color-avanzar-contrast-rgb: 255,255,255;
  --ion-color-avanzar-shade: #3488bf;
  --ion-color-avanzar-tint: #4fa4dd;
  /** ganado **/
  --ion-color-ganado: #66bb6a;
  --ion-color-ganado-rgb: 102,187,106;
  --ion-color-ganado-contrast: #ffffff;
  --ion-color-ganado-contrast-rgb: 255,255,255;
  --ion-color-ganado-shade: #5aa55d;
  --ion-color-ganado-tint: #75c279;
}