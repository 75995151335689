/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
 

@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';

.cal-modal {
    --height: 80%;
    --border-radius: 10px;
    padding: 25px;
}

.event-detail{
    // white-space: break-spaces !important;
    max-width: 30px !important;
}


ion-content{
    // --ion-background-color: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );
    --ion-background-color: #f8fbfc;
    // --ion-background-color: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);

}
ion-item{
    --ion-background-color: #f8fbfc;
}
ion-card{
    --background:white;
    --ion-item-background: white;
    }


